//This section is used as a homepage in this section posts has been imported and then it is imported from post section to render all the
//product card
import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import styles from "./ProductListing.module.css";
import Posts from "../../components/posts/posts";
import { useSearchParams } from "react-router-dom";
import { baseUrl } from "../../config";

export default function ProductListing({ handleContactDeveloper }) {
  const [called, setCalled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, _] = useSearchParams();

  useEffect(() => {
    const currentCode = searchParams.get("code");
    const token = localStorage.getItem("access_token");
    if (currentCode && !called && !token) {
      setCalled(true);
      fetch(`${baseUrl}/auth/github`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          code: currentCode,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data["access_token"]) {
            localStorage.setItem("access_token", data["access_token"]);
            fetch(`https://api.github.com/user`, {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: `token ${data["access_token"]}`,
              },
            })
              .then((res) => {
                return res.json();
              })
              .then((data) => {
                localStorage.setItem("name", data["name"]);
                localStorage.setItem("username", data["login"]);
                localStorage.setItem("avatar_url", data["avatar_url"]);
                window.location.href = "/";
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [called, searchParams]);

  return (
    <>
      <div className={styles.pageSection}>
        <Navbar className={styles.nav} />
        {/* <div className={styles.Container}>
          <div className={styles.left}>
            <span className={styles.span1}>LOREM IPSUM</span>
            <br />
            <span className={styles.span2}>
              Make our ideas come <br />
              to life!
            </span>
            <span className={styles.span3}>
              Lorem ipsum dolor sit amet consectetur
              <br />
              adipisicing elit. Reprehenderit nemo dolore
              <br />
              quibusdam quas aspernatur, quis nulla, error eligendi amet
              exercitationem facere nobis,
              <br />
              aut aliquid ipsam omnis cum minus? Repellendus laboriosam corrupti
              velit?
            </span>
          </div>
          <div className={styles.right}>
            <img src={cat} />
          </div>
        </div> */}
        {/* hero starts */}
        <div className="dark:bg-gray-900">
          <div className="relative px-4 xl:px-0 container mx-auto md:flex items-center gap-8">
            <div className="text-color w-full md:w-1/3 pt-16 lg:pt-32 xl:pt-12">
              <h1 className="text-4xl md:text-4xl lg:text-6xl w-11/12 lg:w-11/12 xl:w-full xl:text-6xl text-gray-900   dark:text-white font-extrabold f-f-l">
                Build, review and ship with ease
              </h1>
              <div className="f-f-r text-base lg:text-base pb-20 sm:pb-0 pt-10 xl:pt-6">
                <h2 className=" dark:text-white">
                  The next big thing starts here. Start building with Prodify
                  and be the first to market with a product that is ready to
                  take on the competition and delight your customers
                </h2>
              </div>
              <div className="lg:flex">
                <button className="hidden md:block hover:opacity-90 text-base w-full xl:text-base xl:w-6/12 mt-4 xl:mt-8 f-f-r py-4  bg-indigo-700 text-white font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 rounded-lg">
                  Start building now
                </button>
                <button className="hidden md:block hover:opacity-90 text-base w-full xl:text-base xl:w-4/12 lg:ml-2 xl:ml-2 mt-4 xl:mt-8 f-f-r py-4  bg-indigo-200 text-indigo-600 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 rounded-lg">
                  Try it out
                </button>
              </div>
            </div>
            <img
              className="w-full mt-8 md:mt-0 object-fill md:w-2/3 md:-ml-4 lg:-ml-4 xl:ml-0"
              src="https://tuk-cdn.s3.amazonaws.com/can-uploader/Graphic.png"
              alt="sample page"
            />
            <button className="md:hidden hover:opacity-90 text-base w-full xl:text-base xl:w-6/12 mt-4 xl:mt-8 f-f-r py-4  bg-indigo-700 text-white font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 rounded-lg">
              Start building now
            </button>
            <button className="md:hidden hover:opacity-90 text-base w-full xl:text-base xl:w-4/12 xl:ml-2 mt-4 xl:mt-8 f-f-r py-4  bg-indigo-200 text-indigo-600 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 rounded-lg">
              Try it out
            </button>
          </div>
        </div>
        <style>{`
        .top-100 {
            animation: slideDown .5s ease-in-out;
        }

        @keyframes slideDown {
            0% {
                top: -50%;
            }

            100% {
                top: 0;
            }
        }

        * {
            outline: none !important;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-tap-highlight-color: transparent;
        } `}</style>

        {/* hero ends */}
        <h2 className={styles.productLister}>OUR BEST SELLERS</h2>

        <div>
          <Posts handleContactDeveloper={handleContactDeveloper} />
        </div>
      </div>
    </>
  );
}
