import React, { useEffect, useRef, useState } from "react";
import styles from "./chat.module.css";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const ChatWindow = ({ selectedUser, setUser, chatOpen }) => {
  const [messages, setMessages] = useState([
    {
      from: "developer",
      message: "Hello world. This is a test message from the developer",
    },
    {
      from: "user",
      message: "Hello world. This is a test message from the user",
    },
  ]);
  const [message, setMessage] = useState("");
  const messageContRef = useRef();

  const sendMessage = (message) => {
    setMessages((old) => [...old, { from: "user", message }]);
  };

  useEffect(() => {
    messageContRef?.current
      ?.querySelector(":scope > :last-child")
      ?.scrollIntoView();
  }, [messages]);

  return (
    <div className={`${styles.chatWindow} ${chatOpen ? "" : styles.hidden}`}>
      <div className={`${styles.header} ${styles.chatWindowHead}`}>
        <ArrowBackIosNewIcon
          onClick={() => {
            setUser(null);
          }}
        />
        <div className={styles.headerContent}>
          <h2>Chat with {selectedUser.name}</h2>
          <p>Welcome</p>
        </div>
      </div>
      <div className={styles.messages} ref={messageContRef}>
        {messages.map((message, i) =>
          message.from === "user" ? (
            <div key={i} className={`${styles.message} ${styles.user}`}>
              {message.message}
            </div>
          ) : (
            <div key={i} className={`${styles.message} ${styles.server}`}>
              {message.message}
            </div>
          )
        )}
      </div>
      <form
        className={styles.inputContainer}
        onSubmit={(e) => {
          e.preventDefault();
          sendMessage(message);
          setMessage("");
        }}
      >
        <input
          type="text"
          placeholder="Type message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button type="submit">
          <SendIcon />
        </button>
      </form>
    </div>
  );
};

export default ChatWindow;
