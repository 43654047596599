import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProductListing from "./pages/productslisting/ProductListing";
import DeveloperDashbord from "./pages/develperDashboard/DeveloperDashbord";
import SingleProduct from "./pages/productOpen.jsx/SingleProduct";
import UserDashboard from "./pages/userDashboard/userDashboard";
import Footer from "../src/components/footer/Footer";
import ProductsPage from "./pages/productspage/ProductsPage";
import Faq from "./pages/faq";
import { useState } from "react";
import { UserProvider } from "./context/UserContext";
import { ChatProvider } from "./context/ChatContext";
import Lecture from "./pages/lecture/Lecture";

export default function App() {
  const [openChat, setOpenChat] = useState(false);
  //   const [users, setUsers] = useState([
  //     { email: "priyanka.makhija05@gmail.com", password: "Abc@123!" },
  //   ]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const handleContactDeveloper = (currentUser) => {
    console.log("cuser = ", currentUser);
    if (currentUser == null) {
      setOpenLogin(true);
    } else {
      setOpenChat(true);
    }
  };
  //   const setTheCurrentUser = (user) => {
  //     console.log("cuser in stcu = ", currentUser);
  //     setCurrentUser(user);
  //   };
  // this.handleContactDeveloper = this.handleContactDeveloper.bind()
  // return (
  //   <>
  //     {/* <Navbar /> */}
  //     <div className="h">
  //       <BrowserRouter>
  //         <Routes>
  //           <Route path="/">
  //             <Route index element={<ProductListing />} />
  //             <Route path="developer" element={<DeveloperDashbord />} />
  //             <Route path="products" element={<ProductsPage />} />
  //             {/* <Route path="product/:slug" element={<SingleProduct setOpenLogin={setOpenLogin} openLogin = {openLogin} openChat={openChat} setOpenChat={setOpenChat} currentUser={currentUser} setCurrentUser={setCurrentUser} isLoggedIn = {isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>} /> */}
  //             <Route path="product/:slug" element={<SingleProduct handleContactDeveloper={handleContactDeveloper} openChat={openChat} isLoggedIn={isLoggedIn} openLogin={openLogin} setCurrentUser = {setTheCurrentUser}/>} />

  return (
    <UserProvider>
      <ChatProvider>
        <div className="h">
          <BrowserRouter>
            <Routes>
              <Route path="/">
                <Route
                  index
                  element={
                    <ProductListing
                      handleContactDeveloper={handleContactDeveloper}
                    />
                  }
                />
                <Route path="developer" element={<DeveloperDashbord />} />
                <Route path="products" element={<ProductsPage />} />
                <Route
                  path="product/:slug"
                  element={
                    <SingleProduct
                      setOpenLogin={setOpenLogin}
                      openLogin={openLogin}
                      openChat={openChat}
                      setOpenChat={setOpenChat}
                      isLoggedIn={isLoggedIn}
                      setIsLoggedIn={setIsLoggedIn}
                      handleContactDeveloper={handleContactDeveloper}
                    />
                  }
                />
                <Route path="lecture" element={<Lecture />} />
                <Route path="userDashboard" element={<UserDashboard />} />
                <Route path="faq" element={<Faq />} />
              </Route>
            </Routes>
            <Footer />
          </BrowserRouter>
        </div>
      </ChatProvider>
    </UserProvider>
  );
}
