import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react";
import { UserContext } from "./UserContext";
import ChatWidget from "../components/chat/ChatWidget";

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chatOpen, setChatOpen] = useState(false);
  const { currentUser } = useContext(UserContext);

  return (
    <ChatContext.Provider value={{ chatOpen, setChatOpen }}>
      {children}
      {currentUser ? (
        <ChatWidget
          chatOpen={chatOpen}
          setChatOpen={setChatOpen}
          username={currentUser.username}
          devUsername={""}
        />
      ) : (
        ""
      )}
    </ChatContext.Provider>
  );
};
