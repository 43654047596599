import React from 'react'
import './Footer.css'
import whatsapp from '../../assets/whatsapp.png'
import facebook from '../../assets/facebook.png'
import gmail from '../../assets/gmail.png'

export default function Footer() {
  return (
    <div className="pg-footer">
    <footer className="footer">
      <svg className="footer-wave-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 100" preserveAspectRatio="none">
        <path className="footer-wave-path" d="M851.8,100c125,0,288.3-45,348.2-64V0H0v44c3.7-1,7.3-1.9,11-2.9C80.7,22,151.7,10.8,223.5,6.3C276.7,2.9,330,4,383,9.8 c52.2,5.7,103.3,16.2,153.4,32.8C623.9,71.3,726.8,100,851.8,100z"></path>
      </svg>
      <div className="footer-content">
        <div className="footer-content-column">
          <div className="footer-logo">
              <span className="hidden-link-text">LOGO</span>
              <h1>DevHustle</h1>
          </div>
        </div>
        <div className='side-links'>
        <div className="footer-content-column">
          <div className="footer-menu">
            <h2 className="footer-menu-name"> Company</h2>
            <ul id="menu-company" className="footer-menu-list">
              <li className="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="#">Contact</a>
              </li>
              <li className="menu-item menu-item-type-taxonomy menu-item-object-category">
                <a href="#">News</a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="#">Careers</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-content-column">
          <div className="footer-menu">
            <h2 className="footer-menu-name"> Quick Links</h2>
            <ul id="menu-quick-links" className="footer-menu-list">
              <li className="menu-item menu-item-type-custom menu-item-object-custom">
                <a target="_blank" rel="noopener noreferrer" href="#">Support Center</a>
              </li>
              <li className="menu-item menu-item-type-custom menu-item-object-custom">
                <a target="_blank" rel="noopener noreferrer" href="#">Service Status</a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="#">Security</a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="#">Blog</a>
              </li>
              <li className="menu-item menu-item-type-post_type_archive menu-item-object-customer">
                <a href="#">Customers</a></li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="#">Reviews</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-content-column">
          <div className="footer-menu">
            <h2 className="footer-menu-name"> Quick Links</h2>
            <ul id="menu-quick-links" className="footer-menu-list">
              <li className="menu-item menu-item-type-custom menu-item-object-custom">
                <a target="_blank" rel="noopener noreferrer" href="#">Support Center</a>
              </li>
              <li className="menu-item menu-item-type-custom menu-item-object-custom">
                <a target="_blank" rel="noopener noreferrer" href="#">Service Status</a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="#">Security</a>
              </li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="#">Blog</a>
              </li>
              <li className="menu-item menu-item-type-post_type_archive menu-item-object-customer">
                <a href="#">Customers</a></li>
              <li className="menu-item menu-item-type-post_type menu-item-object-page">
                <a href="#">Reviews</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-content-column">
          <div className="footer-call-to-action">
           <button className='footer-call-to-action-button'>Register</button>
           
          </div>
          <div className="footer-call-to-action">
          <button className='footer-call-to-action-button Login-button'>Login</button>
          </div>
        </div>
        </div>
        
        
      </div>
      <div className="footer-copyright">
        <div className="footer-copyright-wrapper">
          <p className="footer-copyright-text">
            ©2020.| All rights reserved.
          </p>
          <div className='icons-right'>
            <span>Follow us</span> 
          <span className='icons-holder'><img src={whatsapp} alt="/"/></span>
          <span className='icons-holder'><img src={facebook} alt="/"/></span>
          <span className='icons-holder'><img src={gmail} alt="/"/></span>
          {/* <span className='icons-holder'><img src={whatsapp} alt="/"/></span> */}
          </div>
        </div>
      </div>
    </footer>
  </div>
  )
}
