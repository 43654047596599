import React, { useEffect, useState } from "react";
import socket from "./socket";
import ChatButton from "./ChatButton";
import ChatWindow from "./ChatWindow";
import styles from "./chat.module.css";
import ChatList from "./ChatList";

// const buttons = [
//   { label: "first", value: "1" },
//   { label: "second", value: "2" },
// ];

function ChatWidget({ username, devUsername, chatOpen, setChatOpen }) {
  const [users, setUsers] = useState([]);
  // const [user, setUser] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  // let devUsername;

  useEffect(() => {
    // const username = prompt("Enter name");
    // devUsername = prompt("Enter name of developer");
    console.log("toggling...");
    // toggleWidget();
    for (let i = 0; i < users.length; i++) {
      console.log("message user = ", users[i]);
      if (users[i].username === devUsername) {
        setSelectedUser(users[i]);
        break;
      }
    }
    console.log(username);
    socket.auth = { username };
    socket.connect();
    console.log("connected = ", socket);
    setSelectedUser({ username, userID: socket.id });

    socket.on("private message", ({ content, from }) => {
      console.log("Private message from server", content);
      // for (let i = 0; i < users.length; i++) {
      //   const u = users[i];
      //   // if (u.userID === from) {
      //   //   console.log("Private message from server for self",content);
      //   //   if(!user.messages)
      //   //   user.messages=[];
      //   //   user.messages.push({
      //   //     content,
      //   //     fromSelf: false,
      //   //   });
      //   //   if (user !== selectedUser) {
      //   //     user.hasNewMessages = true;
      //   //   }
      //   //   break;
      //   // }
      // }
      // addResponseMessage(content);
    });
    return () => {
      socket.off("connect_error");
    };
  }, [devUsername, username, users]);

  // socket.on("user connected", (u) => {
  //   console.log("users = ",users);
  //   users.push(u);
  //   setUsers(users);
  // });

  socket.on("users", (us) => {
    us.forEach((u) => {
      if (u.username === devUsername) setSelectedUser(u);
      u.self = u.userID === socket.id;
      // return u;
    });
    // put the current user first, and then sort by username
    // setUsers(
    let temp = us.sort((a, b) => {
      if (a.self) return -1;
      if (b.self) return 1;
      if (a.username < b.username) return -1;
      return a.username > b.username ? 1 : 0;
    });
    setUsers(temp);
    console.log("users = ", users);
  });

  const onMessage = (content) => {
    // console.log("message = ",content);
    if (selectedUser) {
      socket.emit("private message", {
        content,
        to: selectedUser.userID,
      });
      // this.selectedUser.messages.push({
      //   content,
      //   fromSelf: true,
      // });
      // addUserMessage(content);
    }
  };

  const handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    onMessage(newMessage);
    // Now send the message throught the backend API
  };

  useEffect(() => {
    // addResponseMessage('Welcome to this awesome chat!');
    // setQuickButtons(buttons);
  }, []);

  const userList = [{ name: "User 1" }, { name: "User 2" }, { name: "User 3" }];
  const [selectedDev, setSelectedDev] = useState(null);
  return (
    <div className={styles.container}>
      {selectedDev ? (
        <ChatWindow
          chatOpen={chatOpen}
          selectedUser={selectedDev}
          setUser={setSelectedDev}
        />
      ) : (
        <ChatList
          chatOpen={chatOpen}
          userList={userList}
          setUser={setSelectedDev}
        />
      )}
      <ChatButton chatOpen={chatOpen} setChatOpen={setChatOpen} />
    </div>
  );
}

export default ChatWidget;
