import React, { useRef } from "react";
import styles from "./chat.module.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ChatList = ({ userList, setUser, chatOpen }) => {
  const messageContRef = useRef();

  return (
    <div className={`${styles.chatWindow} ${chatOpen ? "" : styles.hidden}`}>
      <div className={`${styles.header}`}>
        <h2>Chat with a Developer</h2>
        <p>Select a developer!</p>
      </div>
      <div
        className={`${styles.messages} ${styles.userList}`}
        ref={messageContRef}
      >
        {userList.map((user) => (
          <div
            className={styles.user}
            onClick={() => {
              setUser(user);
            }}
          >
            <span>{user.name}</span>
            <ArrowForwardIosIcon className={styles.arrowIcon} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatList;
