import React from "react";
import styles from "./chat.module.css";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import CloseIcon from "@mui/icons-material/Close";

const ChatButton = ({ chatOpen, setChatOpen }) => {
  return (
    <div
      className={styles.chatIconContainer}
      onClick={() => {
        setChatOpen((old) => !old);
      }}
    >
      {chatOpen ? (
        <CloseIcon className={styles.chatIcon} />
      ) : (
        <QuestionAnswerIcon className={styles.chatIcon} />
      )}
    </div>
  );
};

export default ChatButton;
